<!--
 * @FileDescription: 自定义Tabs组件
 * @Author: 朱建波
 * @Date: 2023-11-23
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZTabs
 *
 * @Props
 * @property {Array} list                  显示数据
 * @property {String} keyName              list数据为对象时，key值
 * @property {Number} index                选中的index 更新index :index.sync
 * @property {Number} color                选中的颜色
 * @property {Number} cssStyle             自定义样式
 *
 * @Slots
 *
 * @Methods
 * change(index)                            改变选中index
-->
<template>
  <div class="z-tabs-wrapper" :style="{...cssStyle, '--active-color': color}">
    <div :class="['z-tabs-item', idx === current && 'active']" v-for="(item, idx) in showList" :key="idx" @click="handleSelect(idx)">{{ item.label }}</div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    keyName: {
      type: String,
      default: 'label'
    },
    index: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: ''
    },
    cssStyle: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      current: 0,
      showList: []
    }
  },
  watch: {
    list: {
      handler(val) {
        this.showList = this.TransformData()
      },
      immediate: true
    },
    index: {
      handler(val) {
        this.current = val
      },
      immediate: true
    }
  },
  methods: {
    handleSelect(idx) {
      this.current = idx
      this.$emit('change', idx)
      this.$emit('update:index', idx)
    },
    TransformData() {
      return this.list.map(item => {
        const type = typeof item
        if(type === 'object') {
          return {
            ...item,
            label: item[this.keyName]
          }
        }
        return { label: item }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_setting.scss";
.z-tabs {
  &-wrapper {
    box-shadow: inset 0 -2rem 0 $border;
    display: flex;
  }
  &-item {
    height: 60rem;
    padding: 0 25rem;
    font-size: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: var(--active-color, $primary);
    }
    &.active {
      font-weight: 600;
      color: var(--active-color, $primary);
      box-shadow: inset 0 -2rem 0 var(--active-color, $primary);
    }
  }
}
</style>
